import { ENV_BRANCH } from "../../common";

export const isEnvProduction = ENV_BRANCH === "production";
const isEnvNotProductionAndStage =
  ENV_BRANCH !== "production" && ENV_BRANCH !== "stage";

//TODO: remove for now
// export const buildsGalleryCarouselFlag = isEnvNotProduction && true;
// export const pcPackageCarouselFlag = isEnvNotProduction && true;

export const buildsGalleryCarouselFlag = true;
export const pcPackageCarouselFlag = true;

export const basicMenuOptionsFlag = isEnvNotProductionAndStage && true;
export const accountMenuOptionsFlag = isEnvNotProductionAndStage && true;

export const pcPartsDrawerFlag = isEnvNotProductionAndStage && true;
export const peripheralsDrawerFlag = isEnvNotProductionAndStage && true;
export const accessoriesDrawerFlag = isEnvNotProductionAndStage && true;
export const laptopDrawerFlag = isEnvNotProductionAndStage && true;
export const branchMenuFlag = isEnvNotProductionAndStage && true;
export const userBuildFlag = isEnvNotProductionAndStage && true;
export const blogsFlag = true;
export const badWeatherFlag = false;

// custom disable flag
export const disableThisFeature = true;
