import * as pcBuilder from "./pcbuilder";
import * as oauth from "./oauth";
import * as products from "./products";
import * as productDetails from "./product-details";
import * as category from "./category";
import * as cart from "./cart";
import * as app from "./app";
import * as buildsGallery from "./builds-gallery";
import * as banner from "./banners";
import * as flashDeal from "./flash-deal";
import * as location from "./location";
import * as pcPackage from "./pc-package";
import * as priceList from "./pricelist";
import * as options from "./options";
import * as worthItBuilder from "./worth-it-builder";
import * as pcwRegistration from "./pcw-registration";
import * as pcwUserLogin from "./pcw-login";
import * as pcwUserProfile from "./pcw-profile";
import * as pcwUserCart from "./user-cart";
import * as pcwOrderHistory from "./pcw-orders";
import * as pcwUserSocialLogin from "./social-login";
import * as pcwUserAddress from "./pcw-address";
import * as branch from "./branch";
import * as pcwEventCode from "./pcw-event";
import * as tracking from "./tracking";
import * as metaPixel from "./meta-pixel";
import * as kachiPoints from "./kachi-points";
import * as myBuilds from "./my-builds";
import * as blogs from "./blogs";
import * as recentlyViewed from "./recently-viewed-items";
import * as pcBundle from "./pc-bundle";

const modules = {
  app,
  blogs,
  banner,
  flashDeal,
  pcBuilder,
  oauth,
  products,
  buildsGallery,
  location,
  productDetails,
  category,
  cart,
  pcPackage,
  priceList,
  metaPixel,
  worthItBuilder,
  pcwRegistration,
  pcwUserLogin,
  pcwUserProfile,
  pcwUserCart,
  pcwOrderHistory,
  options,
  pcwUserSocialLogin,
  pcwUserAddress,
  branch,
  tracking,
  recentlyViewed,
  pcwEventCode,
  kachiPoints,
  myBuilds,
  pcBundle,
};

export default modules;
