export const metaPixelEarnshaw = "1212485753045487";
export const metaPixelAlabang = "857596619547645";

export const metaPixelLaptop = "1189271192076352";

export const addToCart = "AddToCart";
export const purchase = "Purchase";
export const viewContent = "ViewContent";
export const initiateCheckout = "InitiateCheckout";

export const addressesThatUseAlabangPixel = [
  "las piñas",
  "taguig",
  "parañaque",
  "muntinlupa",
  "cavite", // Lol next time mas specific dapat to... like "bacoor" or "imus"
];

export const addressesThatUseEarnshawPixel = [
  "manila",
  "pasay",
  "makati",
  "mandaluyong",
  "marikina",
  "quezon city",
  "valenzuela",
  "caloocan",
];

export const constants = {
  addToCart,
  purchase,
  viewContent,
  initiateCheckout,
};
